
.main{
    background-color: rgba(125, 125, 125,0.510);
    height: 100%;
    width: 100%;
    position:fixed;
    z-index: 10001000;
    top: 0;
}
.popupPassword{
    height: fit-content;
    width: fit-content;
    position: absolute;
    background-color: white;
    top: 50%;
    left: 50%;
    animation: fadeIn 5s;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-animation: fadeIn 5s;
    z-index: 100000;
}
.popupPassword-body{
    background-size: cover;
    height: auto;
    width: 400px;
    padding: 2em;
}
.popupPassword-header{
    /* position: absolute; */
    /* right: 15px; */
    /* top: 10px; */
    width: 95%;
    margin: 0 auto;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(125, 125, 125,0.510);
    padding: 0.8em;
    /* z-index: 100000 !important; */
    color: #000;

}

@media (min-width: 300px) and (max-width: 480px) {
    .popupPassword-body{
        height: 300px;
        width: 300px;
    }
}
