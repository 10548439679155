/* ****************************************************************************
Common Css Elements
**************************************************************************** */
@font-face {
  font-family: Gothamroundedbook;
  src: url(./Assests/gotham-rounded-book/Gotham\ Rounded\ Book.otf);
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap");
@font-face {
  font-family: GothamroundedbookBold;
  src: url(./Assests/gotham-rounded-book/Gotham\ Rounded\ Bold.otf);
}

@font-face {
  font-family: GothamroundedbookMedium;
  src: url(./Assests/gotham-rounded-book/Gotham\ Rounded\ Medium.otf);
}

@font-face {
  font-family: Gothamroundedbooklight;
  src: url(./Assests/gotham-rounded-book/GothamRoundedLight_21020.ttf);
}

.font_family_bold {
  font-family: "GothamroundedbookBold";
}
.font_family_medium {
  font-family: "GothamroundedbookMedium";
}
.font_family_light {
  font-family: "Gothamroundedbooklight";
}

a.heading {
  color: black !important;
}

.bold {
  font-family: "GothamroundedbookBold" !important;
}

.center-cal {
  top: 50px !important;
  left: 50% !important;
  right: auto !important;
  animation: fadeIn 0.6s;
  transform: translate(-50%) !important;
  -webkit-transform: translate(-50%) !important;
  -moz-transform: translate(-50%) !important;
  -ms-transform: translate(-50%) !important;
  -o-transform: translate(-50%) !important;
  -webkit-animation: fadeIn 0.6s;
}

:root {
  --primary-color: var(--primary-color);
  --secondary-color: #fff;
  --hover-color: #fca527;
  --text-color: #939495;
  --hero-image-overlay: #052355;
  --primary-color-opacity: rgb(239 78 34 / 0.7);
  --primary-color-opacity-2: rgb(238 77 32 / 0.2);
  --whats-app: rgb(126, 211, 79);
  --checkbox-size: 18px;
  --dash-size: 0.65em;
  --hero-image: url(https://roomph.ascendant.travel/v1/images/roomph-banner-2.jpeg);
  --hero-mobile-image: url(https://roomph.ascendant.travel/v1/images/roomph-banner-2.jpeg);
}

.css-qupgtr {
  top: 35% !important;
}

.form-control {
  color: rgb(147, 148, 149) !important;
  font-family: "Gothamroundedbook" !important;
}

body {
  font-family: "Gothamroundedbooklight";
  width: 100%;
  min-height: 100%;
  overflow: overlay;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
  display: block;
}

::-webkit-scrollbar-button {
  height: 10px !important;
  width: 0px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-left: none;
  border-right: none;
  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

::-webkit-scrollbar-track-piece:start {
  margin-top: 10px;
  background: transparent;
}

.fill-primary {
  fill: var(--primary-color) !important;
}

/* ::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar-track {
    display: none;
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary-color) !important;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
} */

.mtt-3 {
  margin-top: 2rem !important;
}

.mbb-3 {
  margin-bottom: 2rem !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.text-light {
  font-family: "Gothamroundedbooklight" !important;
}

.text-lighter {
  font-family: "Gothamroundedbooklight" !important;
}
.hero-content-main-heading h3 {
  color: #fff !important;
}

.code-container {
  min-height: 15rem;
}

.code-container p {
  line-height: 1.3;
}

.mobile-book {
  margin: auto;
}

.capitalize {
  text-transform: capitalize;
}
.hotel {
  font-size: 20px;
  font-family: "GothamroundedbookBold" !important;
  line-height: 1.4;
}

.container {
  zoom: 90% !important;
}

.position-relative {
  position: relative !important;
}

.container-3 {
  width: 60% !important;
  margin: 0 auto !important;
}

.display-flex {
  display: flex;
}

.j-between {
  justify-content: space-between;
}

.item-center {
  align-items: center;
}

.blog-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}
.blog-grid-item {
  flex: 0 1 calc(95% / 3);
}

.btn {
  font-size: 0.85rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !important;
  -moz-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  -ms-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.deals-promotion-container {
  min-height: 60vh;
}

.back-primary {
  background-color: var(--primary-color) !important;
}

.btn-topbar-primary {
  padding: 1.3px 0px !important;
  width: 8.4rem !important;
  height: 2.3rem !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: var(--secondary-color) !important;
  outline: none !important;
  box-shadow: 0px 1px 2px 1px #cccccc !important;
}

.btn-topBar-secondary:hover,
.btn-topbar-primary:hover,
.btn-topbar-primary-outline:hover {
  background-color: var(--hover-color) !important;
  border: 1px solid var(--hover-color) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: var(--secondary-color) !important;
}

.btn-topbar-primary-outline {
  padding: 1.3px 0px !important;
  width: 8.4rem !important;
  height: 2.3rem !important;
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: var(--primary-color) !important;
  outline: none !important;
}

.btn-primary {
  padding: 1.3px 15px !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: var(--secondary-color) !important;
  outline: none !important;
}

.chat-button {
  padding: 6px 8px !important;
  background-color: var(--whats-app) !important;
  border: 1px solid var(--whats-app) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: var(--secondary-color) !important;
  outline: none !important;
  font-size: 1rem;
}

.btn-topBar-secondary {
  padding: 1.3px 0px !important;
  width: 6.4rem !important;
  height: 2.3rem !important;
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: black !important;
  outline: none !important;
  box-shadow: 0px 1px 2px 1px #cccccc !important;
}

.pin {
  width: 0.7rem;
}

.swal2-styled.swal2-confirm {
  background-color: var(--primary-color) !important;
}

.slider-text {
  /* bottom: 10px;
    right: 20px;
    width: 18rem; */
  width: 20%;
  font-family: GothamroundedbookBold !important;
  /* justify-content: center; */
  font-size: 1.9rem;
  padding-right: 45px;
  text-align: right;
  /* text-shadow: 1px 1px #000; */
  text-transform: capitalize;
  color: var(--primary-color);
  line-height: 1.3;
}

.mobile-nav {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 20px 0;
  box-shadow: 1px 1px 2px 1px #ccc;
}

.btn-topBar-secondary-Map {
  padding: 1.3px 0px !important;
  width: 7.4rem !important;
  height: 2.3rem !important;
  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    url(./Assests/bkg-map-entry.svg);
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: black !important;
  outline: none !important;
  box-shadow: 0px 1px 2px 1px #cccccc !important;
}

.btn-medium {
  width: 6rem;
  height: 2.2rem;
}

.cursor {
  cursor: pointer;
}

.featuredImg {
  height: 400px !important;
  width: 100vw;
  position: relative !important;
  background-attachment: fixed;
  background-size: cover;
}

.btn-white {
  padding: 1.3px 18px !important;
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
  border-radius: 45px !important;
  font-weight: 600;
  font-family: Gothamroundedbook !important;
  color: var(--primary-color) !important;
}

.btn-white:hover {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: var(--secondary-color) !important;
}

.btn-primary:hover {
  background-color: var(--hover-color) !important;
  border: 1px solid var(--hover-color) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: var(--primary-color) !important;
}

.percentage-container-div {
  width: 25%;
}

.percentage-container-div p {
  font-size: 1.3rem;
  margin-top: 15px;
  text-align: center;
  line-height: 1.3;
}

.percentage-container {
  background-color: var(--secondary-color);
  border-radius: 50%;
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 12rem;
  color: #000;
  font-size: 30px;
  color: var(--primary-color);
  font-weight: 900;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.btn-primary-seach {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 45px !important;
  font-family: GothamroundedbookMedium !important;
  color: var(--secondary-color) !important;
  width: 100% !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.btn-primary-seach:hover {
  background-color: var(--hover-color) !important;
  border: 1px solid var(--hover-color) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: var(--primary-color) !important;
}

.btn-secondary:hover {
  background-color: var(--hover-color) !important;
  border: 1px solid var(--hover-color) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: var(--secondary-color) !important;
}

.btn-secondary {
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
  border-radius: 45px !important;
  font-family: Gothamroundedbook !important;
  color: var(--primary-color) !important;
  padding: 1.3px 15px !important;
}

.f-10 {
  font-size: 0.95rem !important;
}

.font-italic {
  font-style: italic;
  color: var(--text-color);
}

.italic {
  font-style: italic;
  font-weight: lighter;
}

div,
a,
p,
span {
  font-family: "Gothamroundedbook";
}

.promotion-name {
  font-size: clamp(10px, 12px + 2vw, 20px);
  margin: 10px 5px;
  color: #000;
  font-family: "Gothamroundedbook";
}

.line-height-1 {
  line-height: 1;
}

.promotion-type-text {
  padding-bottom: 5px;
  position: relative;
}

.promotion-type-text::before {
  content: "";
  position: absolute;
  width: 10%;
  height: 1px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  border-bottom: 1px solid var(--primary-color);
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
}

.f-8 {
  font-size: 0.7rem !important;
}

.hotel-checkout-address {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.f-20 {
  font-size: 20px;
}

.f-25 {
  font-size: 23px;
}

.main-heading {
  animation: fadein 1s;
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 4px;
  font-family: GothamroundedbookBold !important;
  margin-top: 0;
  overflow: hidden;
  text-transform: capitalize;
}

.text-capitalize {
  text-transform: capitalize;
}

.aligned a,
.cities a {
  color: black;
  font-family: GothamroundedbookBold !important;
  text-transform: capitalize;
  font-size: 17px;
}

hr {
  color: rgb(0 0 0 / 40%) !important;
}

.sub-heading {
  font-size: 20px;
  animation: fadein 1s;
  margin-top: 0;
  font-family: GothamroundedbookBold !important;
  margin-bottom: 0;
}

.fouth-heading {
  font-family: GothamroundedbookBold !important;
}

.box-shadow-2 {
  /* box-shadow: 0px 0px 16px #3333333d; */
  -webkit-box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.4);
}

.box-shadow-1 {
  -webkit-box-shadow: -1px 1px 0px 1px rgba(0, 0, 0, 0.4);
  box-shadow: -1px 1px 0px 1px rgba(0, 0, 0, 0.4);
}

.box-shadow-3 {
  box-shadow: rgb(205 206 206) 1px 1px 1px 1px;
}

.box-shadow-4 {
  box-shadow: rgb(205 206 206) -1px 1px 1px 1px;
}

.form-control-sm,
.form-select {
  border-radius: 20px !important;
  border: 1px solid rgb(203, 203, 203) !important;
  box-shadow: rgb(205 206 206) 1px 1px 1px 1px !important;
  color: black !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
  padding: 1rem 0.8rem !important;
}

.form-select-custom {
  border-radius: 20px !important;
  border: 1px solid rgb(203, 203, 203) !important;
  box-shadow: rgb(205 206 206) 1px 1px 1px 1px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
  padding: 0.4rem 1rem !important;
  background-clip: padding-box;
  font-size: 0.875rem;
  display: block;
  width: 100%;
  -moz-padding-start: calc(0.75rem - 3px);
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-select-custom:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.form-control-sm::placeholder {
  color: #788088 !important;
  font-size: 14px;
}

.form-control-md {
  border-radius: 20px !important;
  border: 1px solid rgb(203, 203, 203) !important;
  box-shadow: rgb(205 206 206) 1px 1px 1px 1px !important;
  color: black !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.form-control-md::placeholder {
  color: grey !important;
}

/*animations*/

/******************
* Bounce in right *
*******************/

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slow {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-carousel {
  z-index: 0 !important;
}

.searchbox-backdrop {
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9990 !important;
  display: none;
  transition: all 0.5s linear;
}

.w-75 {
  width: 70% !important;
}

.qty input:disabled {
  background-color: white;
}

.qty input {
  border: 0;
  width: 2%;
}

.qty input {
  color: #000;
  display: inline-block;
  vertical-align: top;
  line-height: 25px;
  padding: 0px 10px;
  min-width: 55px;
  text-align: center;
}

.plus,
.child-plus,
.room-plus {
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: var(--primary-color);
  border: 1px solid;
  border-radius: 100px;
}

.minus,
.child-minus,
.room-minus {
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: var(--primary-color);
  border: 1px solid;
  border-radius: 100px;
}

.slower {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.slowest {
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bounceInRight,
.bounceInLeft,
.bounceInUp,
.bounceInDown {
  opacity: 0;
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
}

.fadeInRight,
.fadeInLeft,
.fadeInUp,
.fadeInDown {
  opacity: 0;
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
}

.flipInX,
.flipInY,
.rotateIn,
.rotateInUpLeft,
.rotateInUpRight,
.rotateInDownLeft,
.rotateDownUpRight,
.rollIn {
  opacity: 0;
}

.lightSpeedInRight,
.lightSpeedInLeft {
  opacity: 0;
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
}

/***********
* bounceIn *
************/
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }

  70% {
    -webkit-transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.bounceIn.go {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

/****************
* bounceInRight *
****************/

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;

    -webkit-transform: translateX(50px);
  }

  60% {
    -webkit-transform: translateX(-30px);
  }

  80% {
    -webkit-transform: translateX(10px);
  }

  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;

    transform: translateX(50px);
  }

  60% {
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    opacity: 1;

    transform: translateX(0);
  }
}

.bounceInRight.go {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

/******************
* Bounce in left *
*******************/

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;

    -webkit-transform: translateX(-50px);
  }

  60% {
    -webkit-transform: translateX(30px);
  }

  80% {
    -webkit-transform: translateX(-10px);
  }

  100% {
    opacity: 1;

    -webkit-transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;

    transform: translateX(-50px);
  }

  60% {
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;

    transform: translateX(0);
  }
}

.bounceInLeft.go {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

/******************
* Bounce in up *
*******************/

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;

    -webkit-transform: translateY(50px);
  }

  60% {
    -webkit-transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
  }

  100% {
    opacity: 1;

    -webkit-transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;

    transform: translateY(50px);
  }

  60% {
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    opacity: 1;

    transform: translateY(0);
  }
}

.bounceInUp.go {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

/******************
* Bounce in down *
*******************/

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;

    -webkit-transform: translateY(-50px);
  }

  60% {
    -webkit-transform: translateY(30px);
  }

  80% {
    -webkit-transform: translateY(-10px);
  }

  100% {
    opacity: 1;

    -webkit-transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;

    transform: translateY(-50px);
  }

  60% {
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;

    transform: translateY(0);
  }
}

.bounceInDown.go {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

/**********
* Fade In *
**********/
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    display: block;
  }
}

.fadeIn {
  opacity: 0;
}

.fadeIn.go {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/**********
* Grow in *
***********/

@-webkit-keyframes growIn {
  0% {
    -webkit-transform: scale(0.2);
    opacity: 0;
  }

  50% {
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@keyframes growIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.growIn {
  -webkit-transform: scale(0.2);
  transform: scale(0.2);
  opacity: 0;
}

.growIn.go {
  -webkit-animation-name: growIn;
  animation-name: growIn;
}

/********
* Shake *
********/
@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(10px);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.shake.go {
  -webkit-animation-name: shake;
  animation-name: shake;
}

/********
* ShakeUp *
********/
@-webkit-keyframes shakeUp {
  0%,
  100% {
    -webkit-transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateY(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateY(10px);
  }
}

@keyframes shakeUp {
  0%,
  100% {
    transform: translateY(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateY(10px);
  }
}

.shakeUp.go {
  -webkit-animation-name: shakeUp;
  animation-name: shakeUp;
}

/*************
* FadeInLeft *
*************/

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-50px);
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInLeft {
  opacity: 0;
  -webkit-transform: translateX(-50px);
  transform: translateX(-50px);
}

.fadeInLeft.go {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

/*************
* FadeInRight *
*************/

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(50px);
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInRight {
  opacity: 0;
  -webkit-transform: translateX(50px);
  transform: translateX(50px);
}

.fadeInRight.go {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

/*************
* FadeInUp *
*************/

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }

  /* 50% {
        opacity: 0.3;
    } */

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  opacity: 0;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}

.fadeInUp.go {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/*************
* FadeInDown *
*************/

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  50% {
    opacity: 0;
  }

  70% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInDown {
  opacity: 0;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
}

.fadeInDown.go {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

/*****************
* rotateIn *
*****************/
@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateIn.go {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

/*****************
* rotateInUpLeft *
*****************/

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInUpLeft.go {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

/*******************
* rotateInDownLeft *
*******************/
@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInDownLeft.go {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

/******************
* rotateInUpRight *
*******************/

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInUpRight.go {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

/********************
* rotateInDownRight *
********************/

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

.rotateInDownRight.go {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

/*********
* rollIn *
**********/

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}

.rollIn.go {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}

/*********
* wiggle *
**********/

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
  }

  10% {
    -webkit-transform: skewX(-8deg);
  }

  20% {
    -webkit-transform: skewX(7deg);
  }

  30% {
    -webkit-transform: skewX(-6deg);
  }

  40% {
    -webkit-transform: skewX(5deg);
  }

  50% {
    -webkit-transform: skewX(-4deg);
  }

  60% {
    -webkit-transform: skewX(3deg);
  }

  70% {
    -webkit-transform: skewX(-2deg);
  }

  80% {
    -webkit-transform: skewX(1deg);
  }

  90% {
    -webkit-transform: skewX(0deg);
  }

  100% {
    -webkit-transform: skewX(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: skewX(9deg);
  }

  10% {
    transform: skewX(-8deg);
  }

  20% {
    transform: skewX(7deg);
  }

  30% {
    transform: skewX(-6deg);
  }

  40% {
    transform: skewX(5deg);
  }

  50% {
    transform: skewX(-4deg);
  }

  60% {
    transform: skewX(3deg);
  }

  70% {
    transform: skewX(-2deg);
  }

  80% {
    transform: skewX(1deg);
  }

  90% {
    transform: skewX(0deg);
  }

  100% {
    transform: skewX(0deg);
  }
}

.wiggle.go {
  -webkit-animation-name: wiggle;
  animation-name: wiggle;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

/********
* swing *
*********/

@-webkit-keyframes swing {
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center;
  }

  20% {
    -webkit-transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.swing.go {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

/*******
* tada *
********/

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }

  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
    transform: scale(1);
  }

  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

.tada.go {
  -webkit-animation-name: tada;
  animation-name: tada;
}

/*********
* wobble *
**********/

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

.wobble.go {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

/********
* pulse *
*********/

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.pulse.go {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

/***************
* lightSpeedInRight *
****************/
@-webkit-keyframes lightSpeedInRight {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@keyframes lightSpeedInRight {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

.lightSpeedInRight.go {
  -webkit-animation-name: lightSpeedInRight;
  animation-name: lightSpeedInRight;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/***************
* lightSpeedInLeft *
****************/
@-webkit-keyframes lightSpeedInLeft {
  0% {
    -webkit-transform: translateX(-100%) skewX(30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(20%) skewX(-30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@keyframes lightSpeedInLeft {
  0% {
    transform: translateX(-100%) skewX(30deg);
    opacity: 0;
  }

  60% {
    transform: translateX(20%) skewX(-30deg);
    opacity: 1;
  }

  80% {
    transform: translateX(0%) skewX(15deg);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

.lightSpeedInLeft.go {
  -webkit-animation-name: lightSpeedInLeft;
  animation-name: lightSpeedInLeft;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

/*******
* Flip *
*******/
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(50px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(50px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(50px) translateZ(150px) rotateY(190deg)
      scale(1);
    -webkit-animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(50px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: perspective(50px) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
}

@keyframes flip {
  0% {
    transform: perspective(50px) rotateY(0);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(50px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(50px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(50px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }

  100% {
    transform: perspective(50px) scale(1);
    animation-timing-function: ease-in;
  }
}

.flip.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip;
}

/**********
* flipInX *
**********/
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(50px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(50px) rotateX(-10deg);
  }

  70% {
    -webkit-transform: perspective(50px) rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(50px) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes flipInX {
  0% {
    transform: perspective(50px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(50px) rotateX(-10deg);
  }

  70% {
    transform: perspective(50px) rotateX(10deg);
  }

  100% {
    transform: perspective(50px) rotateX(0deg);
    opacity: 1;
  }
}

.flipInX.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX;
}

/**********
* flipInY *
**********/

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(50px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(50px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(50px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(50px) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes flipInY {
  0% {
    transform: perspective(50px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(50px) rotateY(-10deg);
  }

  70% {
    transform: perspective(50px) rotateY(10deg);
  }

  100% {
    transform: perspective(50px) rotateY(0deg);
    opacity: 1;
  }
}

.flipInY.go {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY;
}

/*****************
* Out animations *
*****************/

/************
* bounceOut *
*************/
@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
  }

  25% {
    -webkit-transform: scale(0.95);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}

.bounceOut.goAway {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

/************
* bounceOutUp *
*************/
@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.bounceOutUp.goAway {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

/************
* bounceOutDown *
*************/
@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}

@keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.bounceOutDown.goAway {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

/************
* bounceOutLeft *
*************/
@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.bounceOutLeft.goAway {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

/************
* bounceOutRight *
*************/
@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.bounceOutRight.goAway {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

/************
* fadeOut *
*************/
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut.goAway {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

/************
* fadeOutUp *
*************/
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

.fadeOutUp.goAway {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

/************
* fadeOutDown *
*************/
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

.fadeOutDown.goAway {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

/************
* fadeOutLeft *
*************/
@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

.fadeOutLeft.goAway {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

/************
* fadeOutRight *
*************/
@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

.fadeOutRight.goAway {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

/************
* flipOutX *
*************/
@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(50px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: perspective(50px) rotateX(90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  0% {
    transform: perspective(50px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(50px) rotateX(90deg);
    opacity: 0;
  }
}

.flipOutX.goAway {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

/************
* flipOutY *
*************/
@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(50px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: perspective(50px) rotateY(90deg);
    opacity: 0;
  }
}

@keyframes flipOutY {
  0% {
    transform: perspective(50px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(50px) rotateY(90deg);
    opacity: 0;
  }
}

.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY;
}

/************
* lightSpeedOutRight *
*************/
@-webkit-keyframes lightSpeedOutRight {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOutRight {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

.lightSpeedOutRight.goAway {
  -webkit-animation-name: lightSpeedOutRight;
  animation-name: lightSpeedOutRight;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

/************
* lightSpeedOutLeft *
*************/
@-webkit-keyframes lightSpeedOutLeft {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-100%) skewX(30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOutLeft {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%) skewX(30deg);
    opacity: 0;
  }
}

.lightSpeedOutLeft.goAway {
  -webkit-animation-name: lightSpeedOutLeft;
  animation-name: lightSpeedOutLeft;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

/************
* rotateOut *
*************/
@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0;
  }
}

.rotateOut.goAway {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

/************
* rotateOutUpLeft *
*************/
@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -transform-origin: left bottom;
    -transform: rotate(-90deg);
    opacity: 0;
  }
}

.rotateOutUpLeft.goAway {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

/************
* rotateOutDownLeft *
*************/

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.rotateOutDownLeft.goAway {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

/************
* rotateOutUpRight *
*************/

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.rotateOutUpRight.goAway {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

/************
* rollOut *
*************/
@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
  }
}

@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg);
  }
}

.rollOut.goAway {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

.w-30 {
  width: 25rem;
}

/* ****************************************************************************
Common Css Elements ended
**************************************************************************** */

/* ****************************************************************************
Topbar css started
**************************************************************************** */
.roomph-logo img {
  max-height: 3rem;
}

.container-2 {
  width: 90%;
  margin: 0 auto;
}

.sign-in {
  cursor: pointer;
  font-size: 0.85rem !important;
}

/* ****************************************************************************
Topbar css started ended
**************************************************************************** */
.home-bar {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  max-height: 4em;
  padding: 8px 0px;
  display: flex;
  align-items: center;
}

.icon-bar {
  position: fixed;
  top: 90%;
  right: 2%;
  z-index: 100;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a:hover {
  background-color: none;
}

.whatsapp {
  color: white;
  width: 100px;
  cursor: pointer;
}
.error_section {
  min-height: 100vh;
  background-size: 100% 100%;
}
.heading404 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 10em;
  /* line-height: 39px; */
  text-align: center;
  color: #ffffff;
}
.subheading404 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #ffffff;
}
.error_container_inner {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.error-container {
  height: 100vh;
}
.plugin {
  width: 100vw;
  position: absolute;
  bottom: 8%;
}
.orange-bar {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  max-height: 4em;
  padding: 8px 0px;
  display: flex;
  align-items: center;
}

.TopSection {
  position: sticky;
  top: 0;
  z-index: 80;
}

.search-hh {
  /* position: sticky !important; */
  top: 3.2em;
  background-color: #fff;
  /* z-index: 90; */
}

.elementToFadeInAndOut {
  opacity: 1;
  animation: fade 2s linear;
}

.content-css a {
  color: var(--primary-color);
  text-decoration: underline !important;
}

.content-css a:hover {
  color: var(--hover-color) !important;
  text-decoration: underline !important;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.blog-content img {
  width: 100%;
  height: 50vh;
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
}

.swal-icon--warning {
  border-color: var(--primary-color) !important;
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
  background-color: var(--primary-color) !important;
}

.swal-icon--warning__body {
  height: 30px;
}

.swal-icon {
  width: 60px;
  height: 60px;
}

/* .swal-modal {
    width: 450px;
} */
.swal-text {
  text-align: center;
}

.swal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swal-modal {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/* ****************************************************************************
herosection css started started
**************************************************************************** */
.deals-container {
  background-color: #fff;
  border-radius: 0px 0px 30px 30px;
  box-shadow: rgb(205 206 206) 1px 1px 1px 1px;
  border: 1px solid rgb(205, 206, 206);
  -webkit-border-radius: 0px 0px 30px 30px;
  -moz-border-radius: 0px 0px 30px 30px;
  -ms-border-radius: 0px 0px 30px 30px;
  -o-border-radius: 0px 0px 30px 30px;
}

.search-container-deals {
  width: 50%;
  margin: 0 auto;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  box-shadow: 3px 3px 1px 2px rgb(0 0 0 / 20%);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.4rem 1rem;
  font-size: 0.9rem;
  line-height: 1.2;
}

.map-container {
  font-size: 1.5rem;
  margin: 0 1rem;
}

.promotion-type {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.priceRs1 {
  color: #ff334f !important;
}
.strike {
  text-decoration: 2px line-through rgba(0, 0, 0, 0.55);
  -webkit-text-decoration: 2px line-through rgba(0, 0, 0, 0.55);
}

.carousel-item-padding-40-px {
  padding: 0 10px;
}
.deals-item-container {
  height: clamp(5em, 11.5em + 4vh, 15em + 1vh);
  width: 100%;
  margin-top: clamp(0.5em, 1.9em + 1vh, 2.3em + 2vh);
  border-radius: clamp(15px, 20%, 30px);
  background-position: center;
  -webkit-border-radius: clamp(15px, 20%, 30px);
  -moz-border-radius: clamp(15px, 20%, 30px);
  -ms-border-radius: clamp(15px, 20%, 30px);
  -o-border-radius: clamp(15px, 20%, 30px);
  position: relative;
  overflow: hidden;
  background-size: cover;
}

.swal-button--catch:not([disabled]):hover,
.swal-button--confirm:hover {
  background-color: var(--primary-color) !important;
}

.swal-button--catch:not([disabled]),
.swal-button--confirm {
  background-color: var(--primary-color);
}

.swal-button {
  border-radius: 45px !important;
  -webkit-border-radius: 45px !important;
  -moz-border-radius: 45px !important;
  -ms-border-radius: 45px !important;
  -o-border-radius: 45px !important;
}

.mr-20 {
  padding-right: 20px;
}

.dealproperty-container {
  height: 20rem;
  background-position: center;
  border-radius: 10px;
  box-shadow: 4px 2px 2px 1px rgb(0 0 0 0.4);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  background-size: cover;
}
.rdrDayNumber span {
  color: var(--primary-color) !important;
}
.rdrDayDisabled .rdrDayNumber span {
  color: #aeb9bf !important;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: rgba(255, 255, 255, 0.9) !important;
}
.red-banneer {
  background-color: var(--primary-color);
  position: absolute;
  left: 0px;
  color: #fff;
  line-height: 0.6;
  bottom: 0px;
  border-radius: 0 50px 50px 0;
  padding: clamp(0.2em, 0.3em + 1vh, 1em) clamp(1em, 1.5em + 1vw, 1.8em);
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
}
.red-banner-main {
  animation: fadein 1s;
  font-size: clamp(1em, 1.1em + 1vw, 1.5em);
  line-height: 1.3;
  margin-bottom: 3px;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.45);
  font-family: GothamroundedbookBold !important;
  margin-top: 0;
}

.inputspan {
  font-size: 12px;
  padding: 3px;
  color: red;
  display: none;
}

input:invalid[focused="true"] {
  border: 1px solid red !important;
}

input:invalid[focused="true"] ~ span {
  display: block !important;
}

.bg-gray {
  background-color: #f5f5f5 !important;
}

.hero_section {
  height: 80vh;
  padding-top: 8%;
  position: relative;
  z-index: 1;
  background-image: var(--hero-image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.spinner-grow {
  width: 1rem !important;
  height: 1rem !important;
}
.hero_grid {
  display: grid;
  /* margin-top: 8%; */
  grid-template-columns: 50% 50%;
  place-self: center;
}
.spinner-border {
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.hero-content-main-heading {
  font-size: clamp(1vw, 2vw + 1em, 2em + 1vw);
  font-family: GothamroundedbookBold !important;
  overflow: hidden;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.3);
  /* text-transform: capitalize; */
}

.hero-content-sub-heading {
  font-size: clamp(2vw + 1em, 4vw + 1em, 2em + 1vw);
  margin-top: 0.5em;
  text-shadow: 1px 2px rgba(0, 0, 0, 0.3);
}

.hero_section_terms {
  border-radius: 0px 0px 30px 30px;
  height: 26rem;
  position: relative;
  z-index: 1;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.3)
    ),
    url(https://roomph.ascendant.travel/uploads/slider/terms-and-conditions_1616677275.jpg);
}
.deals_image {
  height: 45vh;
  background-size: cover;
  background-position: 50%;
}

.z-0 {
  z-index: 0 !important;
}

.border-right1 {
  border-right: 1px solid rgba(0, 0, 0, 0.35) !important;
}
.search-box .wrapper ul {
  background-color: #fff;
}
.hero-container-content-banner:hover {
  color: var(--secondary-color) !important;
}

.hero-container-content-banner {
  position: absolute;
  background: var(--primary-color);
  right: -10px;
  width: 45%;
  bottom: 20%;
  color: #ffff;
  background-position: right;
  border: thin dashed #ffff;
  font-size: clamp(0.7em, 1vw + 0.2em, 1em);
  padding: clamp(0.1em, 0.7em, 1.5em) clamp(0.5em, 1.5em, 1.8em);
  border-radius: 50px 0px 0px 50px;
  word-spacing: 3px;
  letter-spacing: 2px;
  box-shadow: 1px 1px 3px 1px rgb(0 0 0 / 50%);
  -webkit-border-radius: 50px 0px 0px 50px;
  -moz-border-radius: 50px 0px 0px 50px;
  -ms-border-radius: 50px 0px 0px 50px;
  -o-border-radius: 50px 0px 0px 50px;
}

.grid-btn {
  display: grid;
  grid-template-columns: auto auto auto;
}
.search-box {
  background-color: var(--white);
  border-radius: 32px;
  width: 84%;
  float: right;
  padding: 1.5rem 2rem;
  position: relative;
  z-index: 9999;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
  -webkit-border-radius: 35px;
}

.btn-search {
  outline: none;
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1rem;
}

.hero_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #929394;
}

.padding-15 {
  width: 100%;
  padding: 18px 0px 18px 30px !important;
  background-color: transparent !important;
  overflow: hidden !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.cal-1 {
  font-size: 1.4rem;
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.jMcrRR > input {
  padding: 0 0 0 33px !important;
}

.nosubmit {
  width: 89%;
  position: relative;
}

/* .search-box {
    width: 36%
} */

.bi-search {
  color: rgba(0, 0, 0, 0.65) !important;
  /*font-size: 1.25rem;
    */
  font-size: 1.3rem;
  position: absolute;
  z-index: 100;
  left: 0px;
  top: 50%;
  transform: translateY(-60%);
  -webkit-transform: translateY(-60%);
  -moz-transform: translateY(-60%);
  -ms-transform: translateY(-60%);
  -o-transform: translateY(-60%);
}

.sort-popup-close {
  color: var(--secondary-color) !important;
}

.sort-popup-close i {
  color: var(--secondary-color) !important;
}

.nosubmit input {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0px !important;
  /* border-bottom: 1px solid !important; */
  outline: none !important;
  border-color: var(--text-color) !important;
  display: block;
  padding: 0px 0px 0px 30px;
  /* background: transparent url("./Assests/search.svg") no-repeat 2px center ; */
}

.nosubmit input:focus {
  border-radius: 0px !important;
  outline: none !important;
  border-color: var(--text-color) !important;
}

/* .nosubmit {
     */
/* width: 100%;
  border: none; */
/* border-bottom: 1px solid rgb(147, 148, 149); */
/* background: transparent url("./Assests/search.svg") no-repeat 2px center;
} */

/* ****************************************************************************
herosection css  ended
**************************************************************************** */

/* ****************************************************************************
Slider css started
**************************************************************************** */
.slider-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 1.5em;
  height: clamp(9em, 13em + 2vh, 17em);
}

.slide_item {
  /* background: linear-gradient(to right, var(--primary-color), #FF4E4C, #FF9C30) !important; */
  border-radius: 40px;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-whats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
  box-shadow: rgb(205 206 206) 0px 1px 2px 1px;
}

.image-containeer {
  display: flex;
  width: 70%;
}

.slide_item p {
  color: var(--secondary-color);
  font-size: 24px;
}

.oo_outer {
  position: relative;
  /* background-color:black; */
}

/* ****************************************************************************
Slider css ended
**************************************************************************** */

/* ****************************************************************************
Review started
**************************************************************************** */
.reviews-container {
  padding: 10px 3px;
}

.review-container-inner {
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  width: 100%;
  padding: 10px 10px;
  border-radius: 35px;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  /* height: 16.5rem; */
  height: 16.5rem;
  position: relative;
}

/* .search-auto-box.wrapper {
    z-index: 90 !important;
} */
.border-radius {
  border-radius: 14px !important;
  -webkit-border-radius: 14px !important;
  -moz-border-radius: 14px !important;
  -ms-border-radius: 14px !important;
  -o-border-radius: 14px !important;
}

.header-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.header-container img {
  width: 4.5rem !important;
  height: 4.5rem !important;
  border: 1px solid black;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  box-shadow: -1px -1px 0px 2px rgba(0, 0, 0, 0.4);
}

.desc {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  /* margin: 8px 10px 15px 10px; */
}

.desc q {
  font-size: 1rem !important;
}

.desc q {
  font-size: 1rem !important;
}

.header-container h4 {
  font-size: 18px;
  padding: 0px 15px;
  font-family: GothamroundedbookMedium !important;

  /* padding-right: 3rem; */
}

.footer span {
  padding: 0px 10px;
  font-size: 0.8rem;
  font-style: italic;
  position: absolute;
  bottom: 15px;
}

.Ful1 {
  margin-left: 20px;
}

.reviewsHeading {
  margin-bottom: 15px;
}

.TR {
  font-family: Montserrat Bold;
  font-size: 22px;
}

.revHotelPic {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 5px;
  object-fit: cover;
}

.hotelName {
  font-family: Montserrat Bold;
  font-size: 18px;
}

.reviewText {
  font-size: 16px;
}

/* ****************************************************************************
Review Ended 
**************************************************************************** */

/* ****************************************************************************
Blogs started 
**************************************************************************** */
.blogs-outer {
  padding: 10px 3px;
}

.blogs-outer1 {
  padding: 10px 10px;
}

.blog-img {
  border-radius: 30px;
  display: block;
  width: 100%;
  height: 14.9rem;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.blog-card {
  width: 100%;
  background-color: var(--primary-color);
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  display: inline-block;
  height: 19rem;
  text-align: center;
  color: var(--secondary-color) !important;
  text-decoration: none !important;
  font-size: 18px;
}

.blog-card-inner .link {
  color: var(--secondary-color) !important;
  padding: 5px 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* ****************************************************************************
Blogs ended 
**************************************************************************** */

/* ****************************************************************************
bookings started 
**************************************************************************** */
.grid-top {
  display: grid;
  grid-template-columns: auto auto;
  gap: 3em;
}

.grid_my_hotels {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 3em;
}

.grid {
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 1em;
  overflow: hidden;
}

.grid-item-1 img {
  width: 100%;
  height: clamp(9em, 12em, 20em);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.grid_hotel_image img {
  width: 100%;
  height: 15em;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.grid-item-2 .ststus {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  width: fit-content;
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 20px;
  vertical-align: center;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

/* ****************************************************************************
destination css started
**************************************************************************** */

.mpD {
  font-family: Montserrat Bold;
  font-size: 22px;
  white-space: nowrap;
}

.min-h-100 {
  min-height: 70vh;
}

.visbale-destination {
  display: grid;
  grid-template-columns: repeat(2, 47%);
  gap: 0.7em;
  row-gap: 0.7em;
  min-width: 100vw;
  place-items: center;
}
.grid-des-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.RP {
  font-family: Montserrat Thin;
}

.viewAllBtn a {
  color: black !important;
  font-size: 15px !important;
}
.ehyBuL {
  width: 1rem !important;
  height: 1rem !important;
  margin: 0 0.5rem !important;
}
.aligned {
  display: grid;
  grid-template-columns: 33% auto;
  align-items: center;
  border-radius: 50px;
  color: var(--white);
  box-shadow: 1px 1px 2px 1px #b3b3b3;
  background-color: var(--primary-color);
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
}

.cityPic1 {
  width: 5rem;
  height: 5rem;
}

.cityPic {
  width: 8rem;
  height: 8rem;
}

.cityA {
  float: left;
  margin-left: 20px;
  padding-top: 4px;
}

.cityC {
  float: left;
}

.cityNameCountt {
  height: 55px;
  width: 100px;
}

.cityNameCount .desp {
  font-size: 1.05rem;
  font-family: "GothamroundedbookBold" !important;
}

.cityNameCount {
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 15px;
}

.des_item img {
  box-shadow: 0 2px 9px 2px rgb(0 0 0 / 15%);
  border-color: #dddfe2;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  height: 170px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  width: 170px !important;
  border: 0;
  vertical-align: middle !important;
  background-color: transparent;
  background-size: cover !important;
  text-decoration: none;
  font-size: 14px;
  zoom: 100%;
  line-height: 20px;
  font-weight: 400;
}

.owl-next {
  /* width: 29px;
    height: 29px; */
  width: 25px !important;
  height: 26px !important;
  padding-left: 10px !important;
  padding-bottom: 10px !important;
  z-index: 1000 !important;
  border-radius: 50% !important;
  color: var(--primary-color) !important;
  background-color: var(--secondary-color) !important;
  -webkit-box-pack: center;
  justify-content: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  flex-direction: column;
  text-decoration: none;
  position: absolute;
  visibility: visible;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4);
  font-size: 28px !important;
  font-weight: 400 !important;
  line-height: 0.74 !important;
  text-align: left;
}
.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  top: clamp(39%, 39% + 2vh, 40%);
  transition: all 0.5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: var(--white) !important;
  color: var(--primary-color) !important;
  min-width: 25px !important;
  min-height: 25px !important;
  opacity: 1;
  cursor: pointer;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.4);
}
.react-multiple-carousel__arrow::before {
  color: var(--primary-color) !important;
  font-size: 11px !important;
}
.react-multiple-carousel__arrow--left {
  left: calc(1% - 1vw) !important;
}
.react-multiple-carousel__arrow--right {
  right: calc(1% - 1vw) !important;
}
.owl-prev {
  /* width: 29px;
    height: 29px; */
  width: 25px !important;
  height: 26px !important;
  padding-left: 10px !important;
  padding-bottom: 10px !important;
  z-index: 1000 !important;
  border-radius: 50% !important;
  color: var(--primary-color) !important;
  background-color: var(--secondary-color) !important;
  -webkit-box-pack: center;
  justify-content: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  flex-direction: column;
  text-decoration: none;
  position: absolute;
  visibility: visible;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.4);
  font-size: 28px !important;
  font-weight: 400 !important;
  line-height: 0.74 !important;
  text-align: left;
}
underline {
  text-decoration: underline !important;
}

.f-18 {
  font-size: 18px;
}

.f-9 {
  font-size: 0.85rem !important;
}

.main-color {
  color: var(--primary-color);
}

.vertical-align-bottom {
  vertical-align: baseline !important;
}

.owl-theme .owl-prev {
  left: -10px;
  top: 50% !important;
  bottom: auto !important;
  margin: 0px !important;
  transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  -moz-transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -o-transform: translate(0, -50%) !important;
}

.f-12 {
  font-size: clamp(0.3em, 0.3em + 1vw, 0.7em);
}

.line-height7 {
  line-height: 0.7;
}

.line-height9 {
  line-height: 0.9;
}

.imgWid5 {
  width: 15px;
}

.owl-theme {
  position: relative;
}

.owl-theme-des {
  position: relative;
}

.owl-theme .owl-next {
  right: -10px;
  top: 50% !important;
  bottom: auto !important;
  margin: 0px !important;
  transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  -moz-transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -o-transform: translate(0, -50%) !important;
}

.owl-theme-des .owl-prev {
  left: -10px;
  top: 50% !important;
  bottom: auto !important;
  margin: 0px !important;
  transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  -moz-transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -o-transform: translate(0, -50%) !important;
}

.owl-theme-des .owl-next {
  right: -10px;
  top: 50% !important;
  bottom: auto !important;
  margin: 0px !important;
  transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  -moz-transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -o-transform: translate(0, -50%) !important;
}

/* .gradient-right{
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255));
    width: 40px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0px;
    z-index: 1;
    visibility: visible;
} */
.des_item img:hover {
  -webkit-box-shadow: 0 2px 9px 2px rgb(0 0 0 / 15%);
  box-shadow: 0 2px 9px 2px rgb(0 0 0 / 15%);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.on {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  font-size: 0.9rem !important;
  transition: 0.3s ease-out;
  margin: 2px;
  text-align: center;
  background: var(--primary-color) !important;
  color: var(--secondary-color) !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 10px !important;
  width: 44px;
  height: 39px;
  -webkit-border-radius: 10px !important;
}

.off {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  font-size: 0.9rem !important;
  transition: 0.3s ease-out;
  padding: 0;
  margin: 2px;
  text-align: center;
  background: transparent !important;
  color: #111111 !important;
  border: 1px solid #d6d6d6 !important;
  border-radius: 10px !important;
  width: 44px;
  height: 39px;
  -webkit-border-radius: 10px !important;
}

.des_item {
  /* background-color: #000; */
  -webkit-box-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  visibility: visible;
  padding: 10px 0px;
}

.destination-pp {
  text-decoration: none;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: rgb(115, 115, 115);
  padding-top: 4px;
  display: block;
  text-align: center;
  background: var(--primary-color);
  width: 10.5rem;
  border-radius: 50%;
  height: 10.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 9px 2px rgba(0, 0, 0, 0.15);
}

.destination-pp:hover {
  color: rgb(115, 115, 115);
}

.des_item span p {
  text-decoration: none;
  color: var(--primary-color);
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  display: block;
  font-size: 16px;
  line-height: 34px;
  line-height: 22px;
  max-height: 34px;
  overflow: hidden;
  padding-top: 12px;
  position: relative;
  text-align: center;
  width: 100%;
  font-family: GothamroundedbookMedium !important;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  visibility: visible;
  text-transform: capitalize;
}

.text-black {
  color: #000 !important;
}

/* ****************************************************************************
destination css  ended
**************************************************************************** */

/* ****************************************************************************
facilities css  started
**************************************************************************** */
.facilities-container-outer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.buttonApp {
  display: none;
}

.star-container img {
  width: 2rem;
  margin: 1px;
}

/* 
p {
    margin-bottom: 0rem !important;
} */

.facilities-container_inner:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.facilities-container_inner .icons-ament {
  width: 2.3rem;
}

.facilities-container_inner p {
  font-size: 1rem !important;
  font-weight: bold;
  margin-bottom: 0px !important;
}

.facilities-container_inner {
  padding: 0.9rem 0.8rem;
  margin: 0px 0px 0px 20px;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* ****************************************************************************
facilities css  ended
**************************************************************************** */
.start-container img {
  width: 23px;
  margin-right: 1px;
}

.ff-desc {
  display: flex;
  align-items: center;
}

.ff-desc p {
  margin-bottom: 0px !important;
  font-size: 1rem !important;
}

a,
a:hover {
  text-decoration: none !important;
  color: inherit;
}

.explore-row-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="range"]::-webkit-slider-thumb {
  background: var(--primary-color) !important;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  background: var(--primary-color) !important;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  background: var(--primary-color) !important;
}

.exploreCityImage {
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  background-size: contain;
  background-position: center;
  height: 10rem;
  width: 100%;
  background-attachment: fixed;
}

.exlolore-outer {
  padding-left: 8px;
  padding-right: 8px;
}

.explore-craousal {
  position: unset !important;
  width: 100%;
}

.explore-craousal .swiper-button-next,
.explore-craousal .swiper-button-prev {
  background-color: #fff !important;
  width: 27px;
  height: 27px;
  top: 40% !important;
  padding: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  box-shadow: -1px 1px 2px 1px rgba(0, 0, 0, 0.45) !important;
}

.explore-craousal .swiper-button-next::after,
.explore-craousal .swiper-button-prev::after {
  font-size: 0.8rem;
  font-weight: 900;
  color: var(--primary-color);
}

.explore-craousal .swiper-button-next {
  right: -10px;
}

.explore-craousal .swiper-button-prev {
  left: -10px;
}

.explore-row-left {
  width: 30%;
  color: var(--secondary-color);
  padding-right: 8vw;
  font-family: GothamroundedbookBold !important;
  font-size: clamp(1vw, 2vw + 1em, 2.5vw);
}

.explore-row-right {
  width: 75%;
  display: flex;
  justify-content: flex-end;
}

.explore-desc p {
  font-size: 18px !important;
  /* line-height: 1.1; */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  /* margin-bottom: 0.2rem !important; */
}

.explore-row-left .top {
  font-size: 25px;
  color: var(--secondary-color);
}

.explore-row-left .cc {
  font-size: 30px;
  font-family: "GothamroundedbookBold" !important;
  color: var(--secondary-color);
}

.explore-row-left .notton {
  font-size: 25px;
  color: var(--secondary-color);
}

p {
  margin-bottom: 0px !important;
}

.erow img {
  width: 14px;
  padding: 0px;
  margin-right: 2px !important;
  vertical-align: middle !important;
}

.erow {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
/* ****************************************************************************
download App started started
**************************************************************************** */
.download {
  /* width: 190px; */
  height: 70px;
  padding: 0px 9px;
  border: 1px solid #cccccc;
  background: #ffff;
  border-radius: 45px;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  /* box-shadow: 3px 2px 16px #3333333d; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.lowerText {
  margin-bottom: 0px !important;
}

.download:hover {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  color: var(--secondary-color);
  -webkit-box-shadow: inset 0 0 0 0;
  box-shadow: inset 0 0 0 0;
}

.download:hover .color-p {
  color: var(--secondary-color);
}

.color-p {
  color: var(--primary-color);
}

.f-3 {
  font-size: 3em;
}

.p1 {
  font-size: 14px;
  margin-bottom: 0 !important;
  padding-right: 10px;
}

.p2 {
  font-size: 13px;
  font-weight: bolder;
  margin-bottom: 0 !important;
}

.border-right {
  border-right: 1px solid rgb(179, 179, 179) !important;
}

.footer-inner {
  border-bottom: 1px solid var(--text-color);
}

.copy-r {
  position: absolute;
  bottom: 5px;
  padding: 0px 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #575757;
  font-size: 0.8rem;
}

.MuiSlider-root {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
}

/* ****************************************************************************
search-header started 
**************************************************************************** */

.roomphLogoH {
  width: 80%;
}

.arrowBtn {
  border: none;
  background: none;
}

.Deals {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.dropdown-contentTopbar {
  background-color: #ffff !important;
  z-index: 100;
  position: absolute;
  width: 12rem;
  box-shadow: 1px 0px 3px 1px rgba(0, 0, 0, 0.15);
  top: 54px;
  /* right: -10px; */
  padding: 1rem;
  list-style: none !important;
  text-decoration: none !important;
  /* -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px; */
}

.dropdown-contentTopbar hr {
  color: #dfe1e5 !important;
  opacity: 1 !important;
}

.dropdown-contentTopbar .nav-item-drop {
  color: var(--primary-color);
  width: 100%;
}

.dropdown-contentTopbar .nav-item-drop a {
  color: var(--primary-color);
  width: 100%;
}

.dropdown-contentTopbar .nav-item-drop a:hover {
  color: var(--hover-color);
}

/* .sign-in {
    color: var(--primary-color) !important;
  } */
.dropdownTopbar {
  position: relative;
  display: inline-block;
}

.dropdownTopbar:hover .dropdown-contentTopbar {
  display: block;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.headerContainer {
  margin: 0;
  padding: 0;
  margin-left: 10px;
}

.dealsBtn {
  width: 80px;
  height: 21px;
  font-size: 12px;
  font-family: Roboto Light;
  letter-spacing: 1px;
  font-weight: 600;
  color: #fff;
  background-color: #ef4e22;
  border: none;
  border-radius: 45px;
  box-shadow: 1px 1px 1px 1px rgb(205, 206, 206);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
.pointer {
  cursor: pointer;
}
.dealsBtn:focus {
  outline: none;
}

.dealsBtn:hover {
  background-color: #fca527;
  outline: none;
  /* transform: translateY(-1px); */
}

.signinBtn {
  font-weight: 600;
  font-size: 12px;
  border: none;
  color: #ef4e22;
  background-color: white;
  border-color: white;
  white-space: nowrap;
}

.signinBtn:focus,
.signinBtn:hover {
  outline: none;
}

.buttonApp {
  width: 80px;
  height: 22px;
  font-size: 11px;
  font-family: JTMarnie Light;
  letter-spacing: 1px;
  font-weight: 600;
  color: #ef4e22;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 1px 1px 1px 1px rgb(205, 206, 206);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.wrap a:link,
.wrap a:visited {
  background-color: #fff;
  color: #ef4e22;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-align: right;
  text-decoration: none;
  display: inline-block;
}

.wrap a:hover,
.wrap a:active {
  background-color: #fca527;
}

.profileBtn1 {
  width: 80px;
  height: 21px;
  font-size: 12px;
  font-family: Roboto Light;
  letter-spacing: 1px;
  font-weight: 600;
  color: #ef4e22;
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #b3b3b3;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.profileBtn1:focus {
  outline: none;
}

.profileBtn1:hover {
  background-color: #fca527;
}

.otherBtns1 {
  width: 80px;
  height: 21px;
  font-size: 12px;
  padding-top: 3px;
  padding-right: 20px;
  font-family: Roboto Light;
  letter-spacing: 1px;
  font-weight: 600;
  border: none;
  border-bottom: 2px solid #a9a8ad;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.otherBtns1:focus {
  outline: none;
}

.otherBtns1:hover {
  background-color: #fca527;
}

.MuiSlider-thumb {
  background-color: var(--primary-color) !important;
}

.MuiSlider-track {
  background-color: var(--primary-color) !important;
}

.MuiSlider-rail {
  background-color: #c7c7c7 !important;
}

.search-input-container {
  text-align: center;
  padding-left: 20px;
  padding-right: 10px;
  border-radius: 50px;
  position: relative;
  position: relative;
  min-width: 370px;
  box-shadow: 0px 1px 2px 1px #cccccc !important;
}

.serach-input-most-inner {
  width: 100%;
  padding: 10px 0px;
  display: inline-flex;
  font-size: 0.85rem !important;
  /* line-height: 20px !important; */
}

.location .wrapper {
  border: 0px !important;
}

/* .location .wrapper ul{
    position:absolute !important;
    background-color: #ffff;
} */

.location .wrapper input {
  padding-left: 0px !important;
  font-size: 0.85rem !important;
  text-transform: capitalize;
}

.location .wrapper input::placeholder {
  font-size: 0.85rem !important;
  color: black !important;
  text-transform: capitalize;
}

.location .wrapper ul {
  position: absolute !important;
  background-color: #fff;
  width: max-content !important;
  margin-top: 15px;
}

.location .wrapper .line {
  display: none;
}

.line-height {
  line-height: 0.7 !important;
}

.signUpCapsule {
  background: linear-gradient(
    to right,
    var(--primary-color),
    #ff4e4c,
    #ff9c30
  ) !important;
  color: var(--secondary-color);
  padding: 0.8rem 0.5rem;
  font-weight: bold;
  font-size: 18px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.pp-3 {
  padding: 0.8rem 0rem !important;
}

.d-none {
  display: none;
}

ww-50 {
  width: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.location {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  text-transform: capitalize;
  color: #000 !important;
  height: 0px !important;
}

.date-time {
  flex-grow: 1.5;
  flex-basis: 0;
  min-width: 0;
  border-right: 1px solid #cccccc !important;
  border-left: 1px solid #cccccc !important;
}

.adults-rroms {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  padding-right: 25px;
}

.search-icon1 {
  width: 35px;
  height: 35px;
  /* padding: 15px; */
  background-color: var(--primary-color);
  border-radius: 50%;
  color: var(--secondary-color);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: absolute;
  z-index: 1000;
  right: 0px;
  top: 50%;
  bottom: auto;
  transform: translate(0%, -50%);
}

.search-icon {
  width: 25px;
  height: 25px;
  margin: 5px !important;
}

.clear-icon {
  margin: 0px !important;
}

.search-icon1 i {
  color: var(--secondary-color) !important;
  font-size: 0.8rem;
  text-align: center;
  position: inherit !important;
  left: 50% !important;
  top: 55% !important;
  transform: translate(50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(50%, -50%) !important;
  -ms-transform: translate(50%, -50%) !important;
  -o-transform: translate(50%, -50%) !important;
}

.search-auto-box .wrapper {
  position: relative !important;
  border-bottom: thin solid rgba(0, 0, 0, 0.35) !important;
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
}

.wrapper input {
  padding-left: 35px !important;
}
/* .wrapper ul {
  background-color: #fff;
  width: 100%;
} */

/* .search-auto-box.wrapper:hover {
    box-shadow: none !important;

}

.search-auto-box.wrapper:focus-within {
    box-shadow: none !important;
} */

.ss-bb {
  appearance: none;
  border: 0;
  text-align: center !important;
  justify-content: center;
  margin: 0;
  outline: 0;
  background-color: transparent;
  padding-top: 2px;
  padding-bottom: 2px;
  overflow: visible;
  outline: none;
  align-items: center;
  z-index: 1;
  cursor: pointer;
  color: #000;
}

/* .ss-bb span {
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    overflow: clip;
    position: absolute;
    white-space: nowrap;
    width: 1px;
} */

/* ****************************************************************************
search-header Ended 
**************************************************************************** */

/* ****************************************************************************
Sign-up modal started 
**************************************************************************** */
.sign-in-container {
  border-radius: 15px 15px 15px 15px;
  border: 1px solid rgb(203, 203, 203);
  background-color: var(--secondary-color) !important;
  margin: 10% auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0rem;
  width: 25rem;
  height: fit-content;
  -webkit-border-radius: 15px 15px 15px 15px;
  -moz-border-radius: 15px 15px 15px 15px;
  -ms-border-radius: 15px 15px 15px 15px;
  -o-border-radius: 15px 15px 15px 15px;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.65) !important;
}

.css-3uo0vg {
  height: fit-content !important;
  width: fit-content !important;
  display: contents !important;
}

.text-center p {
  margin-bottom: 0px !important;
}

.fb {
  color: rgba(6, 116, 225) !important;
}

.white-btn {
  width: 100%;
  height: 30px;
  font-size: 15px;
  background-color: var(--secondary-color);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgb(0 0 0 / 10%);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

/* ****************************************************************************
Sign-up modal started 
**************************************************************************** */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary-color) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading-div {
  width: 100%;
  height: 100vh;
}

.inner-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

section {
  display: block;
}

#map_canvas {
  position: relative;
  overflow: hidden;
}

#desktop-map {
  position: relative;
  overflow: hidden;
}

div#map_canvas {
  height: calc(100vh - 51.61px);
}

div#desktop-map {
  height: 100vh;
}

.ft-16 {
  font-size: 1rem !important;
}

.ft-15 {
  font-size: 0.8rem !important;
}

.text-orange {
  color: var(--primary-color);
}

.text-black {
  color: #111111 !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.z-index {
  z-index: 1;
}

.feature-hotel-description-desk {
  padding: 0px 10px;
}

.feature-hotel-title-desk {
  width: 75%;
}

.feature-hotel-star-rating-desk {
  width: 25%;
  text-align: right;
}

.feature-hotel-box-desk {
  border-bottom: 1px solid #dbdbdb;
}

.feature-hotel-img-desk img {
  height: 120px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.w-100 {
  width: 100% !important;
}

.z-index-999 {
  z-index: 99999 !important;
}

.has-search .form-control-feedback {
  position: absolute;
  display: block;
  pointer-events: none;
  color: var(--title-theme-blue);
  top: 35px;
  left: 31px;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.feature-hotel-box {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 5px;
}

.feature-hotel-img {
  width: 28px;
}

a {
  text-decoration: none !important;
  color: initial;
}

.imgWidD {
  width: 2em;
  height: 2em;
  padding: 2px;
  fill: var(--primary-color);
}

.scrollbar {
  overflow-y: auto;
  height: calc(90vh - 50px);
}

#scroll-4::-webkit-scrollbar {
  width: 7px;
  background-color: #666666;
}

#scroll-4::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border: 1px solid #666666;
}

#scroll-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #666666;
}

.w-300 {
  width: 350px;
}

.roboto-light {
  color: #111111;
}

.border-radius-10 {
  border-radius: 10px;
}

.bg-white {
  background-color: #fff !important;
}

.feature-hotel-description.float-left {
  width: calc(100% - 38px);
  margin-left: 10px;
}

.feature-hotel-title {
  width: calc(100% - 70px);
}

.ft-12 {
  font-size: 12px !important;
}

.gr-medium {
  font-family: "gothamroundedmedium" !important;
}

.feature-hotel-img img {
  width: 28px;
  height: 28px;
  border-radius: 6px;
  object-fit: cover;
}

.gurantees-grid {
  display: grid;
  grid-template-columns: 12% 80%;
  gap: 1.5em;
  align-items: center;
}
.gurantees-star {
  width: 3.5rem;
  height: 3.5rem;
  padding: 0.5rem;
}
.strpc2 {
  width: 1em;
  height: 1.5em;
  margin-right: 0.5em;
  overflow: visible;
}
.text-orange {
  color: var(--primary-color) !important;
}

.text-orange:hover {
  color: var(--primary-color);
}

.ft-10 {
  font-size: 10px !important;
}

.ft-11 {
  font-size: 12px !important;
}

.ft-12 {
  font-size: 12px !important;
}

.feature-hotel-star-rating {
  width: 70px;
}

.bottom-0 {
  bottom: 0;
}

.slider {
  position: relative;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: var(--primary-color);
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: var(--text-color);
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 0;
}

.slider__right-value {
  right: 0;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--left {
  z-index: 3;
  left: 0;
}

.thumb--right {
  z-index: 4;
  right: 0;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  width: 18px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  width: 18px;
  pointer-events: all;
  position: relative;
}

/* ****************************************************************************
Property Details css started 
**************************************************************************** */
.Property-images-row {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.is-primary {
  position: absolute !important;
  text-align: center !important;
  text-decoration: none !important;
  width: auto !important;
  border: none;
  box-shadow: 1px 1px 2px 1px #ccc;
  outline: none !important;
  padding: 7px 15px !important;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s,
    -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s !important;
  -webkit-tap-highlight-color: transparent !important;
  background: var(--secondary-color) !important;
  color: var(--primary-color) !important;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  display: inline-block !important;
  margin: 0px !important;
  text-align: center !important;
  text-decoration: none !important;
  right: 20px;
  bottom: 30px;
}

.reviewCount1 {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  width: 42px;
  border: 1px solid #fff;
  border-radius: 50% 50% 0px 50%;
  box-shadow: inset 1px 1px 0px 2px #c7c7c7;
  height: 42px;
  text-align: center;
  background-size: cover;
  font-size: 17px;
  color: var(--primary-color);
  font-weight: 700;
  -webkit-border-radius: 50% 50% 0px 50%;
  -moz-border-radius: 50% 50% 0px 50%;
  -ms-border-radius: 50% 50% 0px 50%;
  -o-border-radius: 50% 50% 0px 50%;
}

.react_lightgallery_item {
  width: 100% !important;
  height: 100% !important;
}

.h-390 {
  max-height: calc(60vh - 64px) !important;
}

.h-195 {
  height: calc((60vh - 64px) / 2) !important;
}

.v-good {
  line-height: 0.8;
  font-weight: 900;
}

.hotel-price {
  color: var(--primary-color) !important;
}

.avg {
  font-size: 12px;
  font-style: italic;
  line-height: 0.5;
  font-weight: 900;
}

.accomdationimg {
  width: 24px;
  padding: 2px;
}

.sticky-right-contrainer {
  max-width: 1120px;
  border: 1px px solid rgb(221, 221, 221);
  border-radius: 12px;
  padding: 24px;
  position: sticky;
  top: 9rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
}

.position-sticky-btn {
  position: sticky !important;
  top: 2rem;
  z-index: 1000;
}

.f-15 {
  font-size: 15px;
}

.map-icon {
  width: 1.5rem !important;
}

.third-heading {
  font-size: 18px;
  font-weight: 900;
}

.hotel-reviews-container {
  padding: 10px 3px;
}

.hotel-review-container-inner {
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  width: 100%;
  padding: 10px 10px;
  border-radius: 35px;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
  height: 13.5rem;
  position: relative;
}

.hotel-header-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.hotel-header-container img {
  width: 3.5rem !important;
  height: 3.5rem !important;
  border: 1px solid black;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  box-shadow: -1px -1px 0px 2px rgba(0, 0, 0, 0.4);
}

.hotel-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 15px;
  margin: 8px 0px 15px 0px;
}

.hotel-desc q {
  font-size: 1rem !important;
}

.hotel-desc q {
  font-size: 1rem !important;
}

.hotel-header-container h4 {
  font-size: 18px;
  padding: 0px 10px;
  font-weight: bolder;
  /* padding-right: 3rem; */
}

.hotel-footer .r-name {
  position: absolute;
  bottom: 15px;
  display: flex;
  align-items: flex-end;
}

.hotel-footer .r-name-city {
  font-size: 0.8rem;
  font-style: italic;
}

.review-score {
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50% 50% 0 50%;
  box-shadow: inset 1px 1px 0px 2px #c7c7c7;
  height: 40px;
  text-align: center;
  background-size: cover;
  font-size: 15px;
  color: var(--primary-color);
  font-weight: 700;
  -webkit-border-radius: 50% 50% 0 50%;
  -moz-border-radius: 50% 50% 0 50%;
  -ms-border-radius: 50% 50% 0 50%;
  -o-border-radius: 50% 50% 0 50%;
}

.room-container {
  border-radius: 0px 20px 20px;
  border: 1px solid rgb(205, 206, 206);
  box-shadow: rgb(205 206 206) 1px 1px 1px 1px;
  -webkit-border-radius: 0px 20px 20px;
  -moz-border-radius: 0px 20px 20px;
  -ms-border-radius: 0px 20px 20px;
  -o-border-radius: 0px 20px 20px;
}

.room-image-owl-theme .owl-prev {
  left: -10px;
  top: 35%;
}

.room-image-owl-theme .owl-next {
  right: -10px;
  top: 35%;
}

.book-now {
  width: 300px !important;
  padding: 7px !important;
  z-index: 100;
}

.position-relative {
  position: relative !important;
}

.thankew-btn {
  border-radius: 45px !important;
  color: var(--secondary-color) !important;
  box-shadow: rgb(205 206 206) 1px 1px 1px 1px !important;
  background-color: rgb(126, 211, 79) !important;
  white-space: nowrap;
  -webkit-border-radius: 45px !important;
  -moz-border-radius: 45px !important;
  -ms-border-radius: 45px !important;
  -o-border-radius: 45px !important;
}

.line-1 {
  line-height: 1;
}

.step1 {
  border: none;
  border-radius: 50%;
  color: white;
  background-color: var(--hover-color);
  text-align: center;
  width: 22px;
  height: 22px;
  font-weight: 700;
  font-size: 12px;
  display: table-cell;
  box-shadow: 2px 2px 2px rgb(205 206 206);
  vertical-align: middle;
  border: none;
  border-radius: 50%;
  color: white;
  background-color: var(--hover-color);
  text-align: center;
  width: 22px;
  height: 22px;
  font-weight: 700;
  font-size: 12px;
  display: inline-flex;
  box-shadow: 2px 2px 2px rgb(205 206 206);
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.step2 {
  border: none;
  border-radius: 50%;
  color: var(--hover-color) !important;
  background-color: var(--secondary-color);
  text-align: center;
  width: 22px;
  height: 22px;
  font-weight: 700;
  padding: 6px;
  font-size: 12px;
  display: table-cell;
  box-shadow: 2px 2px 2px rgb(205 206 206);
  vertical-align: middle;
  border: none;
  border-radius: 50%;
  color: white;
  background-color: #ffff;
  text-align: center;
  width: 22px;
  height: 22px;
  font-weight: 700;
  font-size: 12px;
  display: inline-flex;
  box-shadow: 2px 2px 2px rgb(205 206 206);
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.hotelImgCI {
  width: 100%;
  height: 6.5rem;
}

.BButton {
  color: #fff !important;
  background-color: #856ac5 !important;
  border: 1px solid #856ac5 !important;
}

.checkOut-information {
  border-radius: 8px;
  background-color: var(--secondary-color);
  color: rgb(34, 34, 34) !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  position: relative;
}

.checkin-check-out {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
  margin: 0px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  cursor: pointer !important;
  padding: 0px !important;
  text-align: left !important;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #cccc;
}

.anchor-down {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.addults-combo {
  position: relative !important;
  display: flex !important;
  width: 100% !important;
  margin: 0px !important;
  color: black !important;
  background-color: transparent !important;
  box-shadow: none !important;
  cursor: pointer !important;
  padding: 0px !important;
  border-top: none !important;
  text-align: left !important;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #cccc;
  -webkit-border-radius: 0px 0px 8px 8px;
  -moz-border-radius: 0px 0px 8px 8px;
  -ms-border-radius: 0px 0px 8px 8px;
  -o-border-radius: 0px 0px 8px 8px;
}

.addults-combo:focus {
  border-radius: 8px !important;
  border: 3px solid var(--primary-color) !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.top-txt {
  position: absolute !important;
  top: 12px !important;
  left: 12px !important;
  right: 12px !important;
  margin: 0px !important;
  padding: 0px !important;
  pointer-events: none !important;
  font-size: 11px !important;
  font-family: "GothamroundedbookMedium";
  line-height: 12px !important;
  text-transform: uppercase !important;
  font-weight: 900 !important;
  max-width: 100% !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  cursor: pointer;
}
.f-1 {
  font-size: 1rem;
}
.f-2 {
  font-size: 2rem;
}

.input-txt {
  min-height: 56px !important;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  margin: 0px !important;
  color: rgb(113, 113, 113) !important;
  padding: 26px 12px 10px !important;
  background-color: transparent !important;
  font-family: inherit !important;
  font-size: 15px !important;
  font-weight: inherit !important;
  appearance: none !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  cursor: pointer;
}

.chheckii {
  position: relative !important;
  flex: 1 1 0% !important;
  padding: 0px !important;
  overflow: hidden !important;
}

.chheckii:focus {
  border: 2px solid black !important;
}

.checkout-inner {
  display: flex !important;
  width: 100% !important;
  flex: 1 1 0%;
  position: relative !important;
  background: var(--secondary-color, none);
  border-radius: 8px 8px 0px 0px;
  inset: 0px 0px -1px;
  cursor: pointer;
}

.checkin-check-out:focus {
  border-radius: 8px !important;
  border: 3px solid var(--primary-color) !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}

.customer-drop {
  width: 220px;
  background-color: #ffff;
  padding: 6px 15px;
  position: relative;
  text-align: left;
  border-radius: 45px !important;
  border: 1px solid rgb(203, 203, 203) !important;
  box-shadow: rgb(205 206 206) -2px 2px 1px 1px !important;
  color: black !important;
  -webkit-border-radius: 45px !important;
  -moz-border-radius: 45px !important;
  -ms-border-radius: 45px !important;
  -o-border-radius: 45px !important;
}

.customer-drop:hover {
  cursor: pointer;
}

ul.options {
  border: 1px solid #fff;
  display: none;
  list-style: none;
  padding: 4px 0;
  border-radius: 10px !important;
  margin-top: 0px;
  left: 10px;
  position: absolute;
  background: #fff;
  box-shadow: 2px 2px 5px 2px #cccc;
  width: 95%;
  z-index: 1000;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

ul.show {
  display: block;
}

ul.hide {
  display: none;
}

.destination-sidebar {
  position: sticky !important;
  top: 11em !important;
}

ul.options li {
  padding: 6px 10px;
}

ul.options li:active,
ul.options li:focus,
ul.options li:hover,
ul.options li[aria-selected="true"] {
  background: var(--primary-color);
  opacity: 0.8;
  color: #fff;
  cursor: pointer;
}

.wrapper {
  position: relative;
}

.up-price,
.down-price {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: var(--secondary-color) !important;
}

.up-price:after {
  content: "\2191";
  /* width: 4px;
    height: 23px; */
  position: absolute;
  right: 15px;
  color: var(--secondary-color);
}

.asc {
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important;
  color: black !important;
}

.up-price:hover:after {
  color: var(--secondary-color);
}

.omRender:hover::after {
  content: "\2191";
  /* width: 4px;
    height: 23px; */
  position: absolute;
  right: 15px;
  color: var(--secondary-color);
}

.down-price:after {
  content: "\2193";
  /* width: 4px;
    height: 23px; */
  position: absolute;
  right: 15px;
  color: var(--secondary-color);
}

.down-price:hover::after {
  color: var(--secondary-color);
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.search-detail ul li,
.ui-menu-item {
  padding: 8px 10px;
}

.br-theme-bars-square .br-widget a.br-active,
.br-theme-bars-square .br-widget a.br-selected {
  color: #fff !important;
  background: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}

/* .search-detail:before, .ui-autocomplete:before {
    position: absolute;
    top: -19px;
    left: 48px;
    content: '';
    border-width: 10px;
    border-color: transparent transparent var(--primary-color);
    border-style: solid;
} */
#searchData {
  top: 55px;
  min-width: max-content;
  /* height: 270px;
    overflow-y: scroll; */
  left: -10px;
  border-radius: 20px;
  box-shadow: 1px 1px 4px 1px #cccc;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.top-10 {
  top: 4em;
}

.changeDatebtn {
  background-size: 13px;
  /* background-repeat: no-repeat; */
  font-size: 8px;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  padding: 5px;
  width: 100px;
  height: fit-content;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  background-color: rgba(230, 230, 230, 0.5);
  -webkit-border-radius: 10px;
  align-items: center;
  -moz-border-radius: 10px;
  position: relative;
}

.changeDatebtn img {
  width: 20px;
}

@media screen and (min-width: 480px) {
  .changeDatebtn {
    font-size: 12px;
  }
}

#searchData1 {
  min-width: max-content;
  background-color: #ffff !important;
}
.z-index-1 {
  position: relative;
  z-index: -1;
}

.premium-card {
  position: absolute;
  top: 0px;
  width: 6rem;
  height: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 2px 15px 2px 10px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
  left: 0;
  background-color: #ffff;
  border-radius: 0px 50px 50px 0px;
  z-index: 1;
  -webkit-border-radius: 0px 50px 50px 0px;
  -moz-border-radius: 0px 50px 50px 0px;
  -ms-border-radius: 0px 50px 50px 0px;
  -o-border-radius: 0px 50px 50px 0px;
}

.premium-card img {
  width: 100%;
  height: 100%;
}

.nodata {
  padding: 0.3rem;
  text-align: center;
  color: grey;
  opacity: 0.5;
}

.singal-blog-image {
  height: 50vh;
  width: 100%;
  object-fit: fill;
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
}

.room-icon {
  font-size: x-small;
}

.travelTipsBtn {
  width: 80px;
  height: 20px;
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  background-color: var(--primary-color);
  border: none;
  border-radius: 45px;
  box-shadow: 1px 1px 1px 1px rgb(205 206 206);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.im {
  width: 12rem;
}

.links {
  opacity: 1 !important;
  width: 30px;
  height: 30px;
  display: flex;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  border-radius: 100% !important;
  text-align: center !important;
  background-color: var(--secondary-color);
}

.links:hover {
  background-color: var(--primary-color);
}

.links:hover .icons {
  color: var(--secondary-color);
}

.social-li {
  padding: 0px 3px;
}

.icons {
  color: var(--primary-color);
}

/* .footer-right {
    width: 25rem
} */
.infor_hotel_checkout {
  min-height: 50vh;
}
.footer-text {
  font-size: 13px;
  text-align: justify;
  margin: 0px;
  padding: 0px;
}

.f13 {
  font-size: 12px;
}

/* .f-1rem{
    font-size: 0.95rem !important;
} */
.final-booking li {
  margin-top: 0.7rem;
  list-style: none;
  position: relative;
}

.error {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.txt-shadow {
  text-shadow: 0 0 10px rgb(0 0 0 / 75%);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.final-booking li::before {
  content: "\2022";
  color: var(--primary-color);
  font-weight: bold;
  display: inline-block;
  width: 1.3em;
  position: absolute;
  top: 0;
  left: -20px;
}

.custom-checkbox-1 input {
  display: none;
}

.custom-checkbox-1 span {
  border: 3px solid var(--primary-color);
  float: right;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.custom-checkbox-1:hover span,
.custom-checkbox-1 input:checked + span {
  border: 3px solid var(--primary-color);
}

.custom-checkbox-1 input:checked + span:before {
  content: "✔";
}

.no-deals {
  font-size: 30px;
  color: var(--primary-color);
}

.vh-100 {
  min-height: 100vh;
}
.carousel-container {
  position: unset !important;
}

/* ****************************************************************************
Property Details css ended
**************************************************************************** */

/* ****************************************************************************
About us css started
**************************************************************************** */

/* My Bookin Start */
.Accomodation {
  font-size: "18px";
  font-family: "Gotham Rounded Bold";
  margin-left: "15px";
}

.bookingimg {
  width: 60%;
  height: 400px;
}

.bookingstar {
  width: 20px;
  height: 20px;
  margin: 0px 2px;
}

.bookingtext {
  font-size: 14px;
  font-family: Gothamroundedbook;
}

.btnstatus {
  width: 150px;
  height: 50px;
  font-size: 18px;
  font-weight: 900;
  color: #fff;
  background-color: rgb(239, 78, 34);
  border: none;
  border-radius: 45px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

/* My Bookin end */

.aboutUs-second-col {
  width: 5%;
  background-color: #575757;
}

.aboutUsHeadings {
  font-size: 30px;
  font-weight: 900;
  padding-top: 60px;
  font-family: GothamroundedbookBold;
  text-align: center;
  color: #fff;
}

.aboutusbackground {
  background-color: #ef4e22;
}

.aboutUsimage {
  width: 110%;
  height: auto;
}

.AboutusText {
  margin-top: 30px;
  text-align: justify;
  font-family: Gothamroundedbook;
}

.about-heading {
  font-weight: 300;
  display: inline-block;
  padding-bottom: 5px;
  position: relative;
}

.about-heading:before {
  content: "";
  position: absolute;
  width: 15%;
  height: 1px;
  bottom: 0;
  left: 42.5%;
  border-bottom: 1px solid var(--primary-color);
}

.imageText {
  position: absolute;
  bottom: 40%;
  width: 100%;
  font-weight: 900;
  font-size: 20px;
  padding-left: 80px;
  font-family: GothamroundedbookMedium;
  text-align: center;
  color: #ffffff;
}

/* ****************************************************************************
About us css started ended
**************************************************************************** */

/* ****************************************************************************
Terms and Condition css started
**************************************************************************** */

#more {
  display: none;
}

.Terms-second-col {
  width: 5%;
  background-color: #575757;
}

.TermsHeading {
  font-size: 30px;
  font-weight: 900;
  padding-top: 60px;
  font-family: GothamroundedbookBold;
  text-align: center;
  color: #fff;
}

.termsbackground {
  background-color: #ef4e22;
}

.Termsimage {
  width: 105%;
  height: auto;
}

.TermsButton {
  border: 1px solid #ef4e22;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 1000;
  margin-left: 5px;
  padding: 15px 40px 15px 40px;
  background: #ef4e22;
  color: #ffffff;
}

.TermsButton:hover {
  background-color: var(--hover-color) !important;
  border: 1px solid var(--hover-color) !important;
  font-family: Gothamroundedbook !important;
  color: var(--secondary-color) !important;
}

.TermText {
  margin-top: 30px;
  font-weight: 600;
  font-family: Gothamroundedbook;
}

/* ****************************************************************************
Terms and Condition css Ended
**************************************************************************** */
/* Add Property start */

.image {
  width: 50px;
  height: auto;
}

.PropertyText {
  font-family: Gothamroundedbook;
  font-size: 12px;
}

.Address {
  font-family: GothamroundedbookMedium;
}

.third-headings {
  font-size: 16px;
  font-weight: 900;
}

/* Add Property end */

/* ****************************************************************************
Review css Started
**************************************************************************** */

.ReviewHeading {
  font-size: 22px;
  font-family: GothamroundedbookMedium;
  font-weight: 900;
}

.thanksText {
  text-align: start;
  font-size: 22px;
  font-weight: 800;
}

.HeadHeading {
  font-size: 20px;
  font-weight: 900;
  font-family: GothamroundedbookMedium;
  text-align: start;
}

progress {
  background: crimson;
  height: 15px;
}

progress {
  color: #ef4e22;
}

progress::-moz-progress-bar {
  background: #ef4e22;
}

progress::-webkit-progress-value {
  background: greenyellow;
}

progress::-webkit-progress-bar {
  background: var(--primary-color);
}

.blurred-card {
  opacity: 0.5;
  pointer-events: none;
}

.Head {
  padding: 5px;
  margin: 0 5% 0 5%;
  border-bottom: 2px solid #999999;

  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.Headtext {
  font-size: 16px;
  color: var(--primary-color);
}

.cardstyle {
  border-radius: 10px;
  height: 290px;
  margin: 0px;
  border: 1px solid #e7e8e9;
}

.progress {
  position: sticky;
  background-color: #fff;
}

.sliderstyle {
  margin: 0% 0% 2% 0%;
  position: sticky;
}

.thanks {
  color: #000;
  text-align: center;
  text-decoration: underline;
}

.Reviewimage {
  margin-top: 5px;
}

.textarea {
  font-size: 16px;
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid #e7e8e9;
}

.sliderrang {
  font-size: 20px;
  font-weight: 900;
}

.overflow-texts {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

/* .w-25{
    width: 25rem;
} */
/* ****************************************************************************
Review css Ended
**************************************************************************** */
.Buttons {
  width: 100%;
  height: 100%;
  font-size: 0.85rem;
  color: #fff;
  font-family: "Gothamroundedbook" !important;
  background-color: var(--primary-color);
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.viewall {
  background-color: transparent;
  color: #000 !important;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-size: 1em !important;
  cursor: pointer;
}

.image-containeer img {
  width: 2.5rem;
  vertical-align: middle;
  margin: 3px !important;
}

.getText {
  font-size: 0.75rem;
}

.whats-heading {
  font-size: 1rem !important;
  font-family: "GothamroundedbookBold" !important;
}

.chat-button {
  font-size: 0.75rem !important;
}

.h-45 {
  height: 13rem;
}

.hotel-pricing-container-p {
  background-color: var(--primary-color);
  color: #fff;
  padding: 5px 8px 5px 8px;
  border-radius: 30px !important;
  font-size: 0.85rem;
  -webkit-border-radius: 30px !important;
  -moz-border-radius: 30px !important;
  -ms-border-radius: 30px !important;
  -o-border-radius: 30px !important;
}

.hotel-details-container {
  line-height: 1.2;
  white-space: inherit;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  cursor: pointer;
  justify-content: space-between;
}

.imgWidr {
  width: 20px;
  fill: var(--primary-color);
  height: 20px;
  margin-right: 0.2em;
}
.hotel-card {
  height: 13rem;
  box-sizing: border-box;
}

.sliderImgs {
  height: 13rem;
}

.sliderImgs {
  width: 100%;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* .very-good span {
    font-size: 15px;
    font-weight: 900;
} */
.very-good {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.user-rating-container {
  display: flex;
  flex-direction: row-reverse;
  /* margin-top: 15px; */
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 5px;
  /* margin-top: 15px; */
  align-items: flex-end;
}

.freepartTxt {
  font-size: 13px;
}

.thumbnail {
  position: relative;
  display: inline-block;
}

.fullBanner {
  padding: 8px;
}

.caption {
  position: absolute;
  top: 50px;
  padding: 4px;
  left: 0px;
  width: 110px;
  z-index: 2;
  border: 1px solid white;
  border-radius: 5px 30px 30px 5px;
  background-color: #fff;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
}

.container-fluid {
  position: relative !important;
}

.caption1 {
  position: absolute;
  width: 100vw;
  bottom: 0px;
  height: 40px;
  left: -13px;
  z-index: 2;
  background-color: rgba(6, 10, 6, 0.8);
  color: white;
  font-weight: bold;
}

.buttonsSFContainer {
  margin-top: 10px;
}

.filtButn {
  margin-top: 20px;
}

.resLoader {
  margin-bottom: 10px;
}

.caption img {
  width: 90px;
}

.imgWid0 {
  width: 1rem;
}

#popover-basic1 {
  margin-top: 30px;
}

.ww {
  font-size: 20px;
  font-weight: 700;
}

.waves-effect {
  color: #000;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  padding-top: 10px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  font-size: 0.9rem !important;
  transition: 0.3s ease-out;
  padding: 0;
  margin: 2px;
  text-align: center;
  background: transparent !important;
  color: #111111 !important;
  border: 1px solid #d6d6d6 !important;
  border-radius: 10px !important;
  padding-top: 8px;
  width: 44px;
  height: 39px;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}

.reviewCount {
  background-image: url("./Assests/Asset20.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  background-size: cover;
  font-size: 1rem;
  color: var(--primary-color);
  font-weight: 700;
}

.imgwid4 {
  margin-left: -19px;
}

.property_container {
  margin: auto;
  display: flex;
}

.destination-sidebar {
  box-shadow: 0px 3px 12px #00000021;
  border: 1px solid #dcdcdc;
  border-radius: 9px;
  padding-left: 21px;
  padding-right: 21px;
}

.ft-25 {
  font-size: 0.9rem !important;
}

.text-gray {
  color: #666666;
}

.star-rating {
  margin-bottom: 28px !important;
}

.mt-55 {
  margin-top: 55px;
}

.ft-21 {
  font-size: 1rem !important;
}

.text-gray {
  color: #666666;
}

.upperText {
  font-family: GothamroundedbookBold !important;
  font-size: 20px;
  color: var(--primary-color);
}

.download-free {
  font-family: GothamroundedbookBold !important;
  font-size: 18px;
  color: var(--primary-color);
}

.downloadAppContainer {
  margin-top: 25px;
  margin-bottom: 30px;
  margin-left: 17px;
  margin-right: 17px;
}

/* ****************************************************************************
Mobile Responsiveness started
**************************************************************************** */

@media (min-width: 300px) and (max-width: 480px) {
  .chattxt {
    margin-left: -29px;
  }
  .hero_section {
    background-image: var(--hero-mobile-image);
  }

  html {
    font-size: 85%;
  }

  .downloadAppContainer {
    width: 84%;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  /* .blog-card {
        height: 12.5rem;
    } */

  .upperText {
    font-size: 16px;
  }

  .download-free {
    font-size: 15px;
  }

  .reviewCount {
    width: 2.1rem;
    height: 2.1rem;
    font-size: 0.8rem;
  }

  .erow img {
    width: 12px;
  }

  .chatBtn {
    padding-left: 8px;
    padding-right: 8px;
  }

  .rev {
    font-size: 3.9vw;
  }

  .left {
    font-size: 3vw;
  }

  .rs {
    font-size: 3vw;
  }

  .free {
    font-size: 3vw;
  }

  .area {
    font-size: 3.9vw;
    vertical-align: text-top;
  }

  .imgWid1 {
    width: 12vw;
    vertical-align: text-top;
  }

  .chat {
    font-size: 2.5vw;
  }

  .para2 {
    font-size: 18px;
  }

  .imgwiddd {
    margin-top: 10px;
  }

  .chatBtn {
    border: none;
    background-color: #7ed34f;
    padding: 3px;
    margin-left: 0.1px;
    color: white;
    border-radius: 20px;
  }

  .para2 {
    font-size: 13px;
  }

  .chattxt {
    margin-top: -5px;
    line-height: 100%;
  }

  .getText {
    line-height: 0.9;
  }

  .searchLogo {
    margin-top: 5px;
    width: 100px;
  }

  .profileBtn1 {
    padding-top: 3px;
  }

  .signinBtn {
    font-size: 14px;
  }

  .dealsBtn {
    padding-top: 3px;
    width: 100px;
    height: 25px;
    margin-right: 15px;
    margin-top: 5px;
  }

  .topBreak {
    content: "";
    margin: -2px;
    display: block;
    font-size: -10%;
  }

  .dropdown-contentTopbar {
    margin-left: 7px;
  }

  .headerContainer {
    margin-left: 80px;
  }

  .footer-inner {
    border: 1px solid var(--text-color);
    padding: 20px;
  }

  .hotel-pricing-container-p {
    font-size: 0.55rem;
  }

  .freepartTxt {
    font-size: 11px;
  }

  .image-containeer {
    width: 69%;
  }

  .image-containeer img {
    width: 1.7rem;
  }

  .getText {
    font-size: 0.55rem;
  }

  .whats-heading {
    font-size: 0.6rem !important;
    font-family: "GothamroundedbookBold" !important;
  }

  .slider-text {
    /* bottom: 10px;
        right: 20px;
        width: 18rem; */
    width: 30%;
    font-family: GothamroundedbookBold !important;
    /* justify-content: center; */
    font-size: 1rem;
    padding-right: 45px;
    text-align: right;
    /* text-shadow: 1px 1px #000; */
    text-transform: capitalize;
    color: var(--primary-color);
  }

  .card-address {
    font-size: 11px;
  }

  .SearchResultsPage {
    overflow-x: hidden;
  }

  .slide_item {
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
  }

  .mtt-3 {
    margin-top: 1rem !important;
  }

  .mbb-3 {
    margin-bottom: 1rem !important;
  }

  .blog-card-inner .link {
    font-size: 12px;
  }

  .roomph-blogs .owl-item {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }

  .blogs-outer {
    padding: 0px 0px;
  }

  .footer-most-inner {
    flex-direction: column;
  }

  .footer-right {
    width: 100%;
  }

  .social-icons {
    position: absolute;
    top: 0;
    right: 0px;
  }

  .im {
    width: 9rem;
  }

  .footer-text {
    text-align: center;
  }

  .singal-blog-image {
    height: 30vh;
  }

  .blog-content h5 {
    font-weight: bolder;
    font-size: 20px;
  }

  .download {
    padding: 0px 3px;
    height: 3rem;
  }

  .sub-heading {
    font-size: 16px;
  }

  .f-20 {
    font-size: 16px;
  }

  .f-25 {
    font-size: 18px;
  }

  .p1 {
    font-size: 10px;
  }

  .p2 {
    font-size: 12px;
  }

  .f-3 {
    font-size: 2em;
  }

  .mx-sm-2 {
    margin: 0px 5px;
  }

  /* .blog-img {
        height: 150px;
    } */

  .App {
    padding: 0;
    margin: 0;
    border: border-box;
  }

  /* .search-box {
        width: 82%;
    } */

  .owl-theme-des .owl-prev {
    left: -10px;
    top: 30%;
  }

  .owl-theme-des .owl-next {
    right: -10px;
    top: 30%;
  }

  /* .review-container-inner {
        height: 30vh;
    } */

  .main-heading {
    font-size: 21px;
  }

  .nosubmit {
    width: 97%;
    position: relative;
  }

  .desc {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 8px 10px 15px 10px;
  }

  .explore-row-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* .explore-row-right {
        width: 65%;

    } */

  /* .explore-row-left {
        width: 50% !important;
    } */

  .explore-row-left .top {
    font-size: 14px;
  }

  .explore-row-left .cc {
    font-size: 15px;
  }

  .explore-row-left .notton {
    font-size: 14px;
  }

  /* .owl-theme-explore .owl-item {
        padding-left: 4px;
        padding-right: 4px;
        width: 120.981px;
    } */

  .exploreCityImage {
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    height: 6.9rem;
    width: 100%;
  }

  .explore-desc p {
    font-size: 14px !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mBs {
    font-size: 0.9rem;
  }

  .form-group {
    margin-bottom: 0.6rem !important;
  }

  .facilities-mobile {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
  }

  .facilities-mobile-top {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .facilities-container_inner p {
    font-size: 0.7rem !important;
  }

  .facilities-container_inner img {
    width: 1.8rem;
  }

  .facilities-container_inner {
    padding: 0.8rem 0.7rem;
    margin: 0px 0px 0px 10px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .facilities-mobile-right {
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
  }

  .header-container h4 {
    font-size: 13px;
  }

  .header-container img {
    width: 3.5rem !important;
    height: 3.5rem !important;
  }

  .footer span {
    font-size: 0.55rem;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .footer {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .desc q {
    font-size: 0.8rem !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .desc {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .p {
    font-size: 0.6rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem;
  }

  .text-mobile-center {
    text-align: center;
  }

  .text-gray {
    color: grey;
  }

  .imgWidr {
    width: 18px;
  }

  .very-good span {
    font-size: 11px;
    font-weight: bolder;
  }

  .very-good u {
    font-size: 11px;
  }

  .hotel-card {
    height: 10.5rem !important;
  }

  .sliderImgs {
    height: 10.5rem !important;
  }

  .getText {
    font-size: 0.5rem !important;
  }

  .h-45 {
    height: 8.5rem;
  }

  .priceRs1 {
    text-align: center;
  }

  .ss-bb {
    color: #000;
  }

  .text-black {
    color: #000;
  }

  .location {
    border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -ms-border-radius: 0px !important;
    -o-border-radius: 0px !important;
  }

  .h-195 {
    height: calc((35vh - 64px) / 2) !important;
  }

  .Propertyll {
    box-sizing: content-box;
    overflow-x: hidden;
  }

  /* .hotel-name-stars{
    position: absolute;
    top: 0px;
    display: inline-block;
    right: 0;
} */
  .f-15 {
    font-size: 13px !important;
  }

  .location-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .overflow-texts {
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }

  .f-18 {
    font-size: 14px !important;
  }

  /* 

    .hotelImgCI {
        height: 100px;
    } */

  .third-heading {
    font-size: 13px;
  }

  .container-3 {
    width: 70% !important;
    margin: 0 auto !important;
  }

  .sign-in-container {
    width: fit-content !important;
    margin-right: 20px;
    margin-left: 20px;
    position: relative;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .sort-popup .custom-checkbox .custom-control-input {
    opacity: 0 !important;
  }

  .custom-control-label::before {
    opacity: 0 !important;
  }

  #exampleCheck1 {
    opacity: 1 !important;
  }

  .MuiSlider-root {
    position: static !important;
  }

  .swal-icon--warning {
    border-color: var(--primary-color) !important;
  }

  .swal-icon--warning__body,
  .swal-icon--warning__dot {
    background-color: var(--primary-color) !important;
  }

  /* About Us start*/

  .aboutUs-second-col {
    width: 10%;
  }

  .AboutusText {
    font-size: 14px;
    padding-top: 20px;
  }

  .aboutUsHeadings {
    font-size: 16px;
    padding-top: 5px;
  }

  /* About Us end */

  /* Terms and Condition */

  .Terms-second-col {
    width: 10%;
  }

  .TermsHeading {
    font-size: 16px;
    padding-top: 5px;
  }

  /* Terms and Conditions */

  /* Review  start*/

  .HeadHeading {
    font-size: 15px;
    font-weight: 900;
  }

  .slider {
    margin: 0% 0% 3% 0%;
  }

  .Head {
    margin: 0 0% 0 0%;
  }

  .thanksText {
    font-size: 14px;
    text-align: start;
  }

  /* Review end */

  .bookingimg {
    width: 100%;
    height: auto;
  }

  .search-input-container {
    min-width: 300px;
  }

  .blog-img {
    height: 10.5rem;
  }

  .blog-card {
    height: 14rem;
  }

  .review-container-inner {
    height: 13.5rem;
  }

  .helpfull-info {
    margin-bottom: 5rem;
  }

  .percentage-container {
    width: 6rem;
    height: 6rem;
    font-size: 18px;
  }

  .percentage-container-div p {
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
  }

  .txt2 {
    font-size: 10px;
    margin-top: 5px;
    text-align: center;
    font-family: "GothamroundedbookBold" !important;
  }

  .search-container-deals {
    width: 100%;
    margin: 0 auto;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    box-shadow: 3px 3px 1px 2px rgb(0 0 0 / 20%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0.4rem 1rem;
    font-size: 0.75rem;
  }

  .deals-cc {
    padding: 0px !important;
  }

  .dealproperty-container {
    height: 13rem;
  }

  .mx-20 {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }

  .grid-top {
    grid-template-columns: auto;
    gap: 1em;
  }

  .bookingtext {
    font-size: 10px;
  }

  .grid-item-2 .ststus {
    padding: 2px 5px;
    font-size: 9px;
  }
  .hero_grid {
    grid-template-columns: auto;
  }
  .hero_grid_item_1 {
    text-align: center;
    padding: 20px;
  }
  .search-box {
    width: 100%;
  }
  .hero_grid_item_2 {
    display: inline-flex;
  }
  .hero_grid {
    height: fit-content;
    padding: clamp(0.5em, 0.5em + 1vw, 1em);
  }
  .hero-container-content-banner {
    width: 85%;
    bottom: 40px;
    font-size: 15px;
  }

  .padding-15 {
    padding: 10px 0px 10px 30px !important;
  }
  .roomph-logo img {
    max-width: 5rem;
  }
  .deals_image {
    height: 15vh;
  }
}

/* ****************************************************************************
Mobile Responsiveness ended
**************************************************************************** */
